<template>
  <b-card
    v-if="keyValue"
  >
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Nama Lengkap"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.member.nama_lengkap"
              name="name"
              placeholder="Nama Lengkap"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Usia (Tuliskan angkanya saja)"
            label-for="account-age"
          >
            <b-form-input
              v-model="optionsLocal.member.age"
              placeholder="Usia"
              :readonly="true"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="Alamat"
            label-for="account-alamat"
          >
            <b-form-input
              v-model="optionsLocal.member.alamat"
              name="alamat"
              placeholder="Alamat"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Nomor HP"
            label-for="account-hp"
          >
            <b-form-input
              v-model="optionsLocal.member.nomor_hp"
              name="nomor_hp"
              placeholder="Nomor HP"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="genderList"
            label="Jenis Kelamin"
          >
            <v-select
              id="genderList"
              v-model="optionsLocal.member.gender"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="genderOption.options"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="statusPernikahanList"
            label="Status Pernikahan"
          >
            <v-select
              id="statusPernikahanList"
              v-model="optionsLocal.amres.status_pernikahan"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusPernikahanOption.options"
            />
          </b-form-group>
        </b-col>
        <!-- alert -->
        <!-- <b-col
          cols="12"
          class="mt-75"
        >
          <b-alert
            show
            variant="warning"
            class="mb-50"
          >
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link">
                Resend confirmation
              </b-link>
            </div>
          </b-alert>
        </b-col> -->
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="simpan"
          >
            Simpan
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button> //-->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'
import {
  getJenisKelamin,
  getStatusPernikahan,
} from '@/connection-api/master'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    // BImg,
    // BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    // BAlert,
    BCard,
    // flatPickr,
    // BCardText,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    // BLink,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      keyValue: null,
      genderOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Kelamin --' },
        ],
      },
      statusPernikahanOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Status Pernikahan --' },
        ],
      },
      optionsLocal: {},
      profileFile: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      userId: null,
      /* eslint-disable global-require */
      avatar: require('@/assets/images/avatars/default.png'),
      /* eslint-disable global-require */
    }
  },
  unmounted() {
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  },
  async mounted() {
    const responseJenisKelamin = await getJenisKelamin()
    this.mappingSelectOption(responseJenisKelamin.data, this.genderOption.options)

    const responseStatusPernikahan = await getStatusPernikahan()
    this.mappingSelectOption(responseStatusPernikahan.data, this.statusPernikahanOption.options)

    if (this.$route.params.id) {
      this.userId = this.$route.params.id
    } else {
      this.userId = this.userData.id
    }
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    store.dispatch('pengguna/fetchData', { id: this.userId })
      .then(response => {
        this.optionsLocal = response.data.data
        this.keyValue = this.optionsLocal.id
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.optionsLocal = {}
        }
      })
  },
  methods: {
    simpan() {
      this.localData = this.optionsLocal
      if (!this.localData.member) {
        this.localData.member = {}
      }
      if (!this.localData.amres) {
        this.localData.amres = {}
      }
      this.localData.member.gender_id = (this.localData.member.gender == null) ? null : this.localData.member.gender.value
      this.localData.amres.status_pernikahan_id = (this.localData.amres.status_pernikahan == null) ? null : this.localData.amres.status_pernikahan.value
      this.$emit('clicked', this.localData)
      localStorage.setItem('amres', true)
    },
    resetForm() {
      this.optionsLocal = {}
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.optionsLocal.avatar = URL.createObjectURL(file)
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
