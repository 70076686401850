<template>
  <b-card>
    <!-- form -->
    <b-form
      v-if="optionsLocal.amres"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            label-for="countryList"
            label="Saya SUDAH mendapatkan informasi dan sosialisasi Program Belajar Yayasan Plan Internasional Indonesia"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                name="some-radios"
                v-model="optionsLocal.amres.plan_1"
                value="Ya"
              >
                Ya
              </b-form-radio>
              <b-form-radio
                name="some-radios"
                v-model="optionsLocal.amres.plan_1"
                value="Tidak"
              >
                Tidak
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label-for="countryList"
            label="Saya mengikuti Program Belajar ini atas kesadaran / keinginan saya sendiri"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                name="some-radios2"
                v-model="optionsLocal.amres.plan_2"
                value="Ya"
              >
                Ya
              </b-form-radio>
              <b-form-radio
                name="some-radios2"
                v-model="optionsLocal.amres.plan_2"
                value="Tidak"
              >
                Tidak
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <!-- bio -->
        <b-col cols="12">
          <b-form-group
            label="Jika tidak, atas saran / keinginan siapa?"
            label-for="bio-area"
          >
            <b-form-textarea
              id="bio-area"
              rows="4"
              placeholder="Ketik disini..."
              v-model="optionsLocal.amres.plan_3"
            />
          </b-form-group>
        </b-col>
        <!--/ bio -->
        <b-col md="12">
          <b-form-group
            label-for="countryList"
            label="Program Belajar Yayasan Plan Internasional Indonesia, yang akan saya ikuti"
          >
            <div class="demo-inline-spacing"
            >
              <span
                v-for="data in jenisProgramOption.data"
                :key="data.id"
                md="4"
              >
                <b-form-radio
                  name="some-radios4"
                  :value="data.id"
                  v-model="optionsLocal.amres.plan_4"
                >
                  {{data.nama}}
                </b-form-radio>
              </span>
            </div>
            <b-form-input
              id="plan_4_others"
              v-if="optionsLocal.amres.plan_4 === 100"
              v-model="optionsLocal.amres.plan_4_others"
              placeholder="Proyek Lainnya"
              class="mt-1"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label-for="countryList"
            label="Saya tertarik dan memilih sendiri jenis Program Belajar yang saya ikuti"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                name="some-radios5"
                v-model="optionsLocal.amres.plan_5"
                value="Ya"
              >
                Ya
              </b-form-radio>
              <b-form-radio
                name="some-radios5"
                v-model="optionsLocal.amres.plan_5"
                value="Tidak"
              >
                Tidak
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Jika tidak, sebutkan jenis Program Belajar yang Anda minat"
            label-for="bio-area"
          >
            <b-form-textarea
              id="bio-area"
              rows="4"
              placeholder="Ketik disini..."
              v-model="optionsLocal.amres.plan_6"
            />
          </b-form-group>
        </b-col>
        <!-- website -->
       <!--  <b-col md="6">
          <b-form-group
            label-for="website"
            label="Website"
          >
            <b-form-input
              id="website"
              v-model="localOptions.website"
              placeholder="Website address"
            />
          </b-form-group>
        </b-col> -->
        <!--/ website -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="simpan"
          >
            Simpan
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Reset
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BFormRadio,
} from 'bootstrap-vue'
import {
  getJenisProgram,
} from '@/connection-api/master'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormRadio,
    BCard,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: {},
      profileFile: null,
      jenisProgramOption: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      userId: null,
    }
  },
  unmounted() {
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  },
  async mounted() {
    this.jenisProgramOption = await getJenisProgram()

    if (this.$route.params.id) {
      this.userId = this.$route.params.id
    } else {
      this.userId = this.userData.id
    }
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    store.dispatch('pengguna/fetchData', { id: this.userId })
      .then(response => {
        this.optionsLocal = response.data.data
        this.keyValue = this.optionsLocal.id
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.optionsLocal = {}
        }
      })
  },
  methods: {
    simpan() {
      this.localData = this.optionsLocal
      if (!this.localData.member) {
        this.localData.member = {}
      }
      if (!this.localData.amres) {
        this.localData.amres = {}
      }
      this.$emit('clicked', this.localData)
    },
    resetForm() {
      this.localOptions = {}
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
