<template>
  <b-card>
    <!-- form -->
    <b-form v-if="optionsLocal.amres && optionsLocal.amres.jawaban">
      <b-card-text>
        <!-- primary -->
        <b-alert
          variant="primary"
          show
        >
          <h4 class="alert-heading">
            Keterangan
          </h4>
          <div class="alert-body">
            <div>1: SANGAT TIDAK SESUAI dengan Diri Saya</div>
            <div>2: TIDAK SESUAI dengan Diri Saya</div>
            <div>3: CUKUP SESUAI dengan Diri Saya</div>
            <div>4: SESUAI dengan Diri Saya</div>
            <div>5: SANGAT SESUAI dengan Diri Saya</div>
          </div>
        </b-alert>
      </b-card-text>
      <b-row
        v-if="jenisPertanyaan.data"
      >
        <b-col
          v-for="data in jenisPertanyaan.data.data"
          :key="data.pertanyaan_id"
          md="12"
        >
          <b-form-group
            :label="data.urutan+'. '+data.judul"
          >
            <div class="demo-inline-spacing">
              <span
                v-for="jawaban in data.jawaban"
                :key="jawaban.id"
                md="4"
              >
                <b-form-radio
                  :name="data.id"
                  :value="jawaban.id"
                  v-model="optionsLocal.amres.jawaban_resiliensi[data.pertanyaan_id].jawaban"
                >
                  {{jawaban.label}}
                </b-form-radio>
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="simpan"
          >
            Simpan
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Reset
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
    <div
      v-else
    >
      Mohon untuk melengkapi form Data Diri & Proyek Plan terlebih Dahulu.
    </div>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BFormRadio, BAlert,
} from 'bootstrap-vue'
import {
  getPertanyaan,
} from '@/connection-api/master'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    // BFormInput,
    BRow,
    BCol,
    BFormRadio,
    BCard,
    // BFormTextarea,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: {},
      profileFile: null,
      jenisPertanyaan: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      userId: null,
    }
  },
  unmounted() {
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  },
  async mounted() {
    this.jenisPertanyaan = await getPertanyaan(2)

    if (this.$route.params.id) {
      this.userId = this.$route.params.id
    } else {
      this.userId = this.userData.id
    }
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    store.dispatch('pengguna/fetchData', { id: this.userId })
      .then(response => {
        this.optionsLocal = response.data.data
        this.keyValue = this.optionsLocal.id
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.optionsLocal = {}
        }
      })
  },
  methods: {
    simpan() {
      this.localData = this.optionsLocal
      if (!this.localData.member) {
        this.localData.member = {}
      }
      if (!this.localData.amres) {
        this.localData.amres = {}
      }
      this.$emit('clicked', this.localData)
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
