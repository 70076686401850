<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Data Diri</span>
      </template>

      <data-diri
        @clicked="simpanData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Proyek Plan</span>
      </template>

      <program-plan
        @clicked="simpanProgramPlan"
      />
    </b-tab>

    <!-- social links -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Motivasi Prestasi</span>
      </template>

      <motivasi-prestasi
        @clicked="simpanMotivasiPrestasi"
        :key="motivasiPrestasiKey"
      />
    </b-tab>

    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Resiliensi </span>
      </template>

      <resiliensi
        @clicked="simpanResiliensi"
        :key="motivasiResiliensiKey"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import {
  insUser,
  ubahUser,
  uploadFileUser,
} from '@/api/user'
import DataDiri from './DataDiri.vue'
import Resiliensi from './Resiliensi.vue'
import ProgramPlan from './ProgramPlan.vue'
import MotivasiPrestasi from './MotivasiPrestasi.vue'

export default {
  components: {
    BTabs,
    BTab,
    DataDiri,
    ProgramPlan,
    Resiliensi,
    MotivasiPrestasi,
  },
  data() {
    return {
      options: {},
      motivasiPrestasiKey: 0,
      motivasiResiliensiKey: 0,
    }
  },
  methods: {
    async simpanResiliensi(value) {
      // console
      value.form = 'resiliensi'
      if (value.id) {
        const response = await ubahUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Ubah Data Diri Berhasil!')
          localStorage.setItem('amres', true)
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
        // this.makeToast('danger', error.response.data)
        // this.show = false
      }
    },
    async simpanMotivasiPrestasi(value) {
      // console
      value.form = 'motivasiprestasi'
      if (value.id) {
        const response = await ubahUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Ubah Data Diri Berhasil!')
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
        // this.makeToast('danger', error.response.data)
        // this.show = false
      }
    },
    async simpanData(value) {
      // console
      value.form = 'datadiri'
      if (value.id) {
        const response = await ubahUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Ubah Data Diri Berhasil!')
          this.motivasiPrestasiKey += 1
          this.motivasiResiliensiKey += 1
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
        // this.makeToast('danger', error.response.data)
        // this.show = false
      }
    },
    async simpanProgramPlan(value) {
      // console
      value.form = 'programplan'
      if (value.id) {
        const response = await ubahUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Ubah Proyek Plan Berhasil!')
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
        // this.makeToast('danger', error.response.data)
        // this.show = false
      }
    },
    makeToast(variant = null, message = null, title = null) {
      this.$bvToast.toast(message, {
        title: 'Kegiatan',
        variant,
        solid: true,
        autoHideDelay: 10000,
        enableHtml: true,
        allowHtml: true,
      })
    },
  },
}
</script>
